<template>
  <div
    style="
      width: 100%;
      text-align: center;
      height: 100%;
      overflow: auto;
      background: linear-gradient(to right, #9c124e, #931d73);
    "
  >
    <br />
    <div class="circular--square center">
      <img id="newlogo" width="180px" src="/logo_colored.f573e269.svg" />
    </div>
    <br />
    <h2 style="color: white">@new_gruppe</h2>
    <br />

    <!--   <v-row v-for="e in links" :key="e.url">-->

    <div
      class="center_and_50"
      v-for="e in links"
      :key="e.url"
      v-on:click="myhref(e.url)"
    >
      <a :href="e.url">{{ e.bezeichnung }}</a>
    </div>

    <br /><br />
  </div>
</template>

<script>
import {
  collections,
  getDocs,
  query,
  orderBy,
  db,
  collection,
} from "@/firebase/firebase.js";

export default {
  name: "Links",

  data: function () {
    return {
      Personen: [
        { id: 0, Name: "Christoph", Alter: 30 },
        { id: 1, Name: "Dennis", Alter: 50 },
      ],
      links: [],
    };
  },
  methods: {
    getName(Person) {
      return Person.Name;
    },
    myhref(web) {
      window.location.href = web;
    },
    async loadLinks() {
      let linkCollection = collections.get("links");
      let q = query(linkCollection, orderBy("sort", "asc"));
      let links = await getDocs(q);

      links.forEach((doc) => {
        let _data = { ...doc.data() };
        _data.docId = doc.id;
        this.links.push(_data);
      });
    },
  },
  computed: {
    summeAlter: function () {
      let summeAlter = 0;
      this.Personen.forEach((Person) => {
        summeAlter += parseInt(Person.Alter);
      });
      return summeAlter;
    },
  },
  mounted: async function () {
    await this.loadLinks();
  },
};
</script>
