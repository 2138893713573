<template>
      <router-view/>
</template>

<style>
  @import './assets/styles.css';
</style>

<script>


export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {
    loginNew.init("links-new-de", "https://links.new.de", []);
  }
};
</script>
